<template>
    <div :class="data.Class" :style="data.Style">
        <div v-for="(item, n) in data.Data" :key="n" :class="item.Class" :style="item.Style" @click="mainAction(item.OnClick)">
            <b-icon :icon="item.Icon" :size="item.Size" :custom-size="item.CustomSize"></b-icon>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: Object
    }
}
</script>
